// Packages
import React from "react";
import { graphql } from "gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata,
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitter: GatsbyImagePropsMeta,
	};
	lautenschlagerLogo: {
		publicURL: string,
	};
}

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	return (
		<Layout
			location={location}
			title="Anfragen"
			desc=""
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container>
				<H1 className="mb-8">Impressum</H1>
			</Container>
			<Container>
				<div className="max-w-xl p-2 mx-auto my-16 mt-3 text-center bg-gray-100">
					<p className="mb-1 text-xs">Website erstellt von:</p>
					<a href="https://lautenschlager.de/leistungen/webdesign-kassel/" target="_blank" rel="dofollow noreferrer">
						<img
							src={data.lautenschlagerLogo.publicURL}
							title="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
							alt="Webdesigner Kassel - Lautenschlager Marketing & Entwicklung"
							className="mx-auto"
						/>
						<br />
						Webdesigner Kassel - Lautenschlager Marketing & Entwicklung
					</a>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		lautenschlagerLogo: file(relativePath: { eq: "webdesign-kassel-lautenschlager-logo.png" }) {
			publicURL
		}
	}
`;
